<template>
    <div style="max-width: 720px; margin:0 auto;">
      <h4 class="form-title">{{ opts.formName }}</h4>
  
      <el-form label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="时间:" prop="createDate" style="float:right;">
              {{ $util.fmtDate(opts.createDate, "yyyy年MM月dd日") }}
            </el-form-item>
            <el-form-item label="单号:" style="float:right;">
              {{ opts.billCode || '' }}
            </el-form-item>
            <div style="clear: both;"></div>
          </el-col>
        </el-row>
      </el-form>
      <el-form ref="forms" :model="forms" :rules="rules" class="form-inline" label-position="right">
        <el-row>
          <el-col :sm="12">
            <el-form-item label="补卡日期" prop="signDate">
              <el-date-picker v-model="forms.signDate" type="date" :disabled-date="disabledSignDate" :disabled="!startNode" />
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item label="补卡时间" prop="signTime">
              <el-time-select v-model="forms.signTime" start="09:30" step="00:05" end="18:30" style="max-width:120px" :disabled="!startNode" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="补卡原因" prop="reasons">
          <el-input v-model="forms.reasons"  :disabled="!startNode"/>
        </el-form-item>
        <el-form-item label="备注">
          <b>无</b>
          <p style="width:100%;"></p>
        </el-form-item>
      </el-form>
    </div>
  </template>
        
  <script>
  export default {
    name: "hrovertime",
    components: {},
    data() {
      return {
        initOpts: {
          formType: 'BKSQ',
          formCode: 'hrnosign',
          formName: '补卡申请单',
          flowCode: 'hr-nosign',
          status: 0,
        },
        forms: this.formdata,
        rules: {
          signDate: [
            { required: true, message: "请选择要补卡的日期", trigger: "blur" }
          ],
          signTime: [
            { required: true, message: "请选择要补卡的时间", trigger: "blur" }
          ],
          reasons: [
            { required: true, message: "请输入加班的事由", trigger: "blur" }
          ]
        },
        dict: {},
      };
    },
    props: {
      userinfo: {
        type: Object,
        default: {},
      },
      opts: {
        type: Object,
        default: {}
      },
      formdata: {
        type: Object,
        default: { details: [{}, {}, {}] },
      }
    },
  
    methods: {
      back() {
        this.$router.go(-1);
      },
      getInitOpts() {
        return this.initOpts;
      },
      async formCheck() {  
        let p = this.$refs['forms'].validate()
        try {
          await p
        } catch (error) {
          //此处的error为表单校验失败的字段
          if (error) return false;
        }
        console.log(p)
        return true;
      },
      getForms() {
        return this.forms;
      },
      removeItem(index) {
        this.forms.details.splice(index, 1);
        console.log(this.forms.details)
      },
  
      // 设置开始日期 的范围
      disabledSignDate(time) {
        // 只能选择当前时间的前一个月的和当天之前的
        let nowDate = new Date();
        let startRange = new Date().setTime(nowDate.getTime() - (24 * 60 * 60 * 1000 * 15));
        let endRange = new Date().setTime(nowDate.getTime() + (24 * 60 * 60 * 1000 * 2));
        return (
          time.getTime() < startRange || time.getTime() > endRange
        )
      },
    },
    created() {
      let that = this;
  
      if (this.opts && this.opts.flowCode)
      this.initOpts = JSON.parse(JSON.stringify(this.opts));

    },
    watch: {
      formdata: function (newValue, oldValue) {
        console.log(newValue);
      },
    },
    computed: {
      startNode() {
        //草稿或者退回状态
        if (this.initOpts.status == 0 || this.initOpts.status == -10 || this.initOpts.status == '') {
          return true;
        }
        return false;
      },
      endNode() {
        if (this.initOpts.status == 50) {
          return true;
        }
        return false;
      }
    }
  
  };
  </script>
        
  <style scoped></style>
        